<template>
  <div class="app-content" :data-theme="theme">
    <router-view/>
  </div>
</template>

<script>
  import { onMounted, ref, watch, provide, } from "vue";

  export default {
    name: "mainApp",
    setup() {
      const theme = ref(localStorage.getItem("theme") || "light");

      provide("theme", theme);

      const setViewportHeight = () => document.documentElement.style.setProperty("--viewport-height", `${window.innerHeight}px`);

      onMounted(() => {
        setViewportHeight();

        window.addEventListener("resize", setViewportHeight);
      });

      watch(() => theme, (val) => {
        localStorage.setItem("theme", val.value);
      }, { deep: true, });

      return { theme, };
    },
  }
</script>

<style lang="scss" src="@/assets/scss/global.scss"></style>