import { createStore, } from "vuex";

export default createStore({
    state: {
        activeChat: "assistant",
        userData: {},
        chats: {
            assistant: [],
            image: [],
            code: [],
        },
    },
    getters: {
        getUserData: (state) => state.userData,
        getActiveChat: (state) => state.activeChat,
        getActiveChatMessages: (state) => state.chats[state.activeChat] || [],
    },
    mutations: {
        clearActiveChat(state) {
            state.chats[state.activeChat] = [];
        },
        addMessageToActiveChat(state, { message, chat, }) {
            const currentChat = chat || state.activeChat;

            state.chats[currentChat].push(message);

            const arrMessages = Object
                .keys(state.chats)
                .reduce((acc, key) => {
                    acc.push(...state.chats[key]);
                    return acc;
                }, []);

            localStorage.setItem("userMessages", JSON.stringify(arrMessages));
        },
        editMessageInActiveChat(state, { key, value, index, }) {
            const activeMessage = state.chats[state.activeChat][index];

            state.chats[state.activeChat].splice(index, 1, { ...activeMessage, [key]: value, });
        },
        clearAllChats(state) {
            state.chats = {
                assistant: [],
                image: [],
                code: [],
            };
        },
        setActiveChat(state, { chat = "assistant", AIisTyping = false, }) {
            if (AIisTyping) {
                return;
            }

            state.activeChat = chat;
        },
        setUserData(state, { key, val, }) {
            state.userData[key] = val;
        },
        clearUserData(state) {
            state.userData = {};
        },
    },
});