import { createApp } from "vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard3";
import VueTippy from "vue-tippy";

import { PerfectScrollbarPlugin } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/style.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import App from "./App.vue";

const vuetify = createVuetify({
  components,
  directives,
});

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const app = createApp(App);

app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);

app
  .use(vuetify)
  .use(router)
  .use(store)
  .use(VueCookies)
  .use(PerfectScrollbarPlugin)
  .use(VueClipboard)
  .use(VueTippy)
  .mount("#app");
