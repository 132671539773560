<template>
    <div class="d-flex h-screen main-wrapper">
      <vMenu />
      <main class="flex-grow-1 main">
        <div class="main__content">
          <router-view
            @dragFileEnter="showDropArea = true"
            @setFile="setFile"
            :uploadFile="droppedFile"
          />
        </div>
      </main>
      <Transition name="fade">
        <vDropFileArea v-if="showDropArea" @leave="showDropArea = false" @dropFile="setFile" />
      </Transition>
    </div>
  </template>
  
  <script>
    import vDropFileArea from "@/components/vDropFileArea.vue";
    import vMenu from "@/components/vMenuBlockComponent.vue";
    import { ref, onMounted, inject, provide, computed, } from "vue";
    import { useStore, } from "vuex";

    export default {
      name: "vMainTemplate",
      setup() {
        const store = useStore();

        const $cookies = inject("$cookies");
        const showDropArea = ref(false);
        const droppedFile = ref(null);

        const getActiveChat = computed(() => store.getters.getActiveChat);

        provide("showSideMenu", ref(false));
        provide("AIisTyping", ref(false));
        provide("aiVersion", computed(() => {
          if (getActiveChat.value === "image") {
            return "dall-e-3";
          }

          return "gpt-4o";
        }));
        provide("validExtensions", ref([
            {
                name: ".docx",
                value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            },
            {
                name: ".doc",
                value: "application/msword",
            },
            {
                name: ".dot",
                value: "application/msword",
            },
            {
                name: ".docm",
                value: "application/vnd.ms-word.document.macroEnabled.12",
            },
            {
                name: ".dotx",
                value: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
            },
            {
                name: ".dotm",
                value: "application/vnd.ms-word.template.macroEnabled.12",
            },
            {
                name: ".rtf",
                value: "application/rtf",
            },
            {
                name: ".txt",
                value: "text/plain",
            },
            {
                name: ".text",
                value: "text/plain",
            },
            {
                name: ".text",
                value: "text/plain",
            },
            {
                name: ".pdf",
                value: "application/pdf",
            },
            {
                name: ".log",
                value: "text/plain",
            },
            {
                name: ".conf",
                value: "text/plain",
            },
            {
                name: ".csv",
                value: "text/csv",
            },
            {
                name: ".xlsx",
                value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            {
                name: ".xls",
                value: "application/vnd.ms-excel",
            }
        ]));

        const setFile = (val) => {
          showDropArea.value = false;
          droppedFile.value = val;
        };

        onMounted(() => {
          if (!$cookies.isKey("username")) {
            return;
          }

          const username = $cookies.get("username");

          store.commit("setUserData", { key: "username", val: username, });
        });

        return { setFile, showDropArea, droppedFile, };
      },
      components: {
        vMenu,
        vDropFileArea,
      },
    }
  </script>
  
<style scoped lang="scss" src="@/assets/scss/main.scss"></style>