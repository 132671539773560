<template>
    <div class="h-100 d-flex flex-column justify-center">
        <form class="auth__form mx-auto" @submit.prevent="submit">
            <h3 class="auth__form-title">synergy chat</h3>
            <div class="auth__form-field" :class="{ 'invalid': v$.email.$errors.length || invalidAuth, }">
              <div class="d-flex justify-center align-center auth__form-field-prepend">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="icon-path-stroke-active" d="M15 7.05859C15 8.71545 13.6568 10.0586 12 10.0586C10.3431 10.0586 9 8.71545 9 7.05859C9 5.40174 10.3431 4.05859 12 4.05859C13.6568 4.05859 15 5.40174 15 7.05859Z" stroke="#7B7D82" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path class="icon-path-stroke-active" d="M12 13.0586C8.68629 13.0586 6 16.1926 6 20.0586H18C18 16.1926 15.3137 13.0586 12 13.0586Z" stroke="#7B7D82" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <input
                v-model.trim="v$.email.$model"
                class="auth__form-input"
                placeholder="Логин"
                type="text"
                name="email"
                @focus="activeInput($event)"
                @blur="activeInput($event, false)"
                @input="invalidAuth = false"
              />
            </div>
            <div class="auth__form-field mt-4" :class="{ 'invalid': v$.password.$errors.length || invalidAuth, }">
              <div class="d-flex justify-center align-center auth__form-field-prepend">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="icon-path-stroke-active" d="M17.0538 20.0586H6.94619C5.87893 20.0586 5 19.1314 5 18.0056V12.1116C5 10.9857 5.87893 10.0586 6.94619 10.0586H17.0538C18.1211 10.0586 19 10.9857 19 12.1116V18.0056C19 19.1314 18.1211 20.0586 17.0538 20.0586Z" stroke="#7B7D82" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path class="icon-path-stroke-active" d="M14.3055 10.0586H9.74899C9.37449 10.0586 9 9.7177 9 9.24042V7.12678C9 5.42223 10.2483 4.05859 11.8088 4.05859H12.1833C13.7437 4.05859 14.992 5.42223 14.992 7.12678V9.24042C15.0545 9.7177 14.7424 10.0586 14.3055 10.0586Z" stroke="#7B7D82" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path class="icon-path-fill-active" d="M12 18.0586C11.4375 18.0586 11 17.6697 11 17.1697V13.9475C11 13.4475 11.4375 13.0586 12 13.0586C12.5625 13.0586 13 13.4475 13 13.9475V17.1697C13 17.6697 12.5625 18.0586 12 18.0586Z" fill="#7B7D82"/>
                </svg>
              </div>
              <input
                v-model.trim="v$.password.$model"
                class="auth__form-input"
                placeholder="Пароль"
                name="password"
                @focus="activeInput($event)"
                @blur="activeInput($event, false)"
                @input="invalidAuth = false"
                :type="isPassword ? 'password' : 'text'"
              />
              <div class="d-flex justify-center align-center auth__form-field-append">
                <button class="auth__form-field-btn" type="button" @click="isPassword = !isPassword">
                  <svg v-if="isPassword" width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.35521 5L5.31146 6.00917L18.7427 19L19.7864 17.9905L6.35521 5Z" fill="#7B7D82"/>
                    <path d="M7.51644 8.14103L8.49885 9.0912C7.42343 9.97684 6.56706 11.0792 6.01367 12.0438L6.01199 12.047C7.55318 14.5393 9.99859 16.8947 13.085 16.5374C13.9181 16.441 14.6953 16.1655 15.4054 15.7714L16.3999 16.733C15.0158 17.5923 13.4291 18.0473 11.71 17.8224C8.61007 17.4169 6.07535 14.9578 4.47754 12.0698C5.22774 10.6099 6.25397 9.21311 7.51644 8.14103ZM9.75088 6.73288C10.6177 6.35933 11.5556 6.13963 12.5572 6.12398C12.6128 6.12366 13.2731 6.15397 13.5723 6.20156C13.7601 6.23155 13.9471 6.26904 14.1315 6.31565C17.072 7.05656 19.2504 9.46084 20.6201 11.9378C20.0455 13.0601 19.2929 14.1553 18.3954 15.0938L17.4427 14.1723C18.1056 13.4812 18.6583 12.712 19.084 11.9658C19.084 11.9658 18.6559 11.3002 18.3314 10.8824C18.1228 10.6138 17.9034 10.353 17.6729 10.1017C17.4909 9.9035 16.7841 9.23039 16.616 9.09022C15.4876 8.15178 14.1628 7.41544 12.5738 7.42783C11.9584 7.43728 11.3666 7.55462 10.8047 7.75216L9.75088 6.73288Z" fill="#7B7D82"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.191 10.7318L10.9995 11.5135C10.9466 11.669 10.9176 11.8352 10.9176 12.0076C10.9176 12.8786 11.6486 13.5856 12.5492 13.5856C12.7274 13.5856 12.8993 13.5579 13.0601 13.5064L13.8686 14.2884C13.4783 14.5002 13.0281 14.6209 12.5492 14.6209C11.0578 14.6209 9.84727 13.45 9.84727 12.0076C9.84727 11.5444 9.9723 11.1093 10.191 10.7318ZM12.4993 9.39506C12.5158 9.39473 12.5326 9.3944 12.5492 9.3944C14.0405 9.3944 15.251 10.5656 15.251 12.0076C15.251 12.0239 15.251 12.0399 15.2507 12.0559L12.4993 9.39506Z" fill="#7B7D82"/>
                  </svg>
                  <svg v-else width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0237 6.00008C16.5434 6.05001 19.4311 9.2146 20.8774 11.9375C20.8774 11.9375 20.3684 12.979 19.8911 13.6458C19.6601 13.9683 19.4171 14.2825 19.1618 14.5864C18.9797 14.8028 18.7916 15.0135 18.5966 15.2182C16.8533 17.049 14.3498 18.4148 11.7062 17.8845C8.76925 17.2953 6.37359 14.7788 4.93359 12.0723C4.93359 12.0723 5.44488 11.0297 5.92488 10.364C6.13991 10.0654 6.3656 9.77482 6.60194 9.49288C6.78302 9.27684 6.97076 9.06614 7.16482 8.86142C8.70501 7.23835 10.6813 5.98876 13.0237 6.00008ZM13.0113 7.33155C11.0507 7.32423 9.42034 8.41904 8.13047 9.77814C7.95505 9.96289 7.78595 10.1533 7.62218 10.3484C7.40681 10.6053 7.2011 10.8706 7.00504 11.1426C6.80898 11.4142 6.60793 11.7571 6.44848 12.0483C7.71805 14.1817 9.62372 16.1087 11.9681 16.579C14.1527 17.0174 16.1916 15.8131 17.6322 14.3002C17.8083 14.1154 17.9784 13.9247 18.1429 13.7293C18.3759 13.452 18.5976 13.1654 18.8083 12.8708C19.0033 12.5982 19.204 12.2547 19.3632 11.9631C18.0457 9.75784 15.7915 7.37483 13.0113 7.33155Z" fill="#7B7D82"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9049 9.33594C14.3779 9.33594 15.5739 10.5319 15.5739 12.0046C15.5739 13.4775 14.3779 14.6732 12.9049 14.6732C11.4323 14.6732 10.2363 13.4775 10.2363 12.0046C10.2363 10.5319 11.4323 9.33594 12.9049 9.33594ZM12.9049 10.6704C13.6416 10.6704 14.2394 11.2682 14.2394 12.0046C14.2394 12.7412 13.6416 13.339 12.9049 13.339C12.1686 13.339 11.5708 12.7412 11.5708 12.0046C11.5708 11.2682 12.1686 10.6704 12.9049 10.6704Z" fill="#7B7D82"/>
                  </svg>
                </button>
              </div>
            </div>
            <div v-if="errorMessage" class="d-flex ga-1 mt-2 auth__form-errors">
              <svg class="icon-fill auth__form-errors-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C6.48556 22 2 17.514 2 12C2 6.48604 6.48556 2 12 2C17.5144 2 22 6.48604 22 12C22 17.514 17.5144 22 12 22ZM12 3.45117C7.28612 3.45117 3.45117 7.28612 3.45117 12C3.45117 16.7139 7.28612 20.5488 12 20.5488C16.7139 20.5488 20.5488 16.7139 20.5488 12C20.5488 7.28612 16.7139 3.45117 12 3.45117Z" fill="#FF0040"/>
                <path d="M12.3182 15H11.6818C11.0455 15 11.1364 14.46 11.1364 13.83L10.5 7.16998C10.5 6.53998 11.0455 6 11.6818 6H12.3182C12.9545 6 13.5 6.53998 13.5 7.16998L12.8636 13.83C12.8636 14.46 12.9545 15 12.3182 15Z" fill="#FF0040"/>
                <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4478 12.5523 16 12 16C11.4477 16 11 16.4478 11 17C11 17.5523 11.4477 18 12 18Z" fill="#FF0040"/>
              </svg>
              <p class="auth__form-errors-text">{{ errorMessage }}</p>
            </div>
            <v-btn
              class="h-auto mt-4 auth__form-submit"
              type="submit"
              variant="text"
              :disabled="pending"
            >
              Авторизация
            </v-btn>
            <a
              v-if="errorMessage"
              class="mt-2 d-flex align-center justify-center auth__form-support-link"
              href="https://t.me/Stuffai_bot"
              target="_blank"
            >
              Техническая поддержка
            </a>
        </form>
    </div>
</template>

<script>
    import { reactive, ref, inject, } from "vue";
    import { useRouter, } from "vue-router";
    import { useVuelidate, } from "@vuelidate/core";
    import { required, } from "@vuelidate/validators";
    import getValidChatMessages from "@/composables/getValidChatMessages.js";

    export default {
        name: "vAuthForm",
        setup() {
          const state = reactive({
            email: "",
            password: "",
          });
          const isPassword = ref(true);
          const errorMessage = ref("");
          const invalidAuth = ref(false);
          const rules = {
            email: { required, },
            password: { required, },
          };

          const axios = inject("axios");
          const router = useRouter();
          const v$ = useVuelidate(rules, state);
          const pending = ref(false);

          const submit = async () => {
            if (pending.value) {
              return;
            }

            try {
              const formIsValid = await v$.value.$validate();

              if (!formIsValid) {
                return;
              }

              pending.value = true;
              invalidAuth.value = false;
              errorMessage.value = "";

              const { getValidChatMessagesView, } = getValidChatMessages();
              const data = await axios.post(`/auth/access/${v$.value.email.$model}`, {
                  password: v$.value.password.$model,
                  login: v$.value.email.$model,
              });

              const { data: res, } = data;
              
              if (res.status === "1") {
                const { message_chat = [], } = res;
                const messages = message_chat.reduce(getValidChatMessagesView, []);

                localStorage.setItem("userMessages", JSON.stringify(messages));

                router.push("/chat");

                return;
              }

              errorMessage.value = res.message;
              invalidAuth.value = true;
            } catch (err) {
              console.error(err);
            } finally {
              pending.value = false;
            }
          };
          const activeInput = (e, add = true) => {
            const el = e.currentTarget;
            const method = add ? "add" : "remove";

            el.closest(".auth__form-field").classList[method]("active");
          };

          return {
            v$,
            pending,
            isPassword,
            errorMessage,
            invalidAuth,
            activeInput,
            submit,
          };
        },
    }
</script>

<style lang="scss" scoped src="@/assets/scss/auth.scss"></style>