export default function() {
    const isJSONString = (str) => {
        try {
          const parseJSON = JSON.parse(str);
  
          return parseJSON;
        } catch (err) {
          return false;
        }
    };
    const getValidChatMessagesView = (acc, obj) => {
        const serverMessageKeys = ["answer_message", "chat_type", "send_message"];
        const { answer_message, chat_type, send_message, uploadFile, } = obj;

        if (serverMessageKeys.some((key) => !(key in obj))) {
            acc.push(obj);
            return acc;
        }

        const objAnswer = isJSONString(answer_message.replace(/'/g, '"'));
        const userMessage = {
          content: send_message,
          me: true,
          chat: chat_type,
          uploadFile: uploadFile || null,
          isCopied: false,
        };
        const aiMessage = {
          content: objAnswer ? null : answer_message,
          me: false,
          isCopied: false,
          imageDownloaded: (objAnswer || {}).image_url ? false : null,
          chat: chat_type,
          image_url: objAnswer ? objAnswer.image_url : null,
        };
        
        acc.push(userMessage, aiMessage);
        
        return acc;
    };

    return { isJSONString, getValidChatMessagesView, };
}