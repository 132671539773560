import { ref, onMounted, } from "vue";

export default function(size = 744) {
    const isMobile = ref(false);

    isMobile.value = window.innerWidth <= size;
    
    onMounted(() => {
        window.addEventListener("resize", () => {
            isMobile.value = window.innerWidth <= size;
        });
    });

    return { isMobile, size, };
}