import { inject, } from "vue";

import { createWebHistory, createRouter, } from "vue-router";

// templates
import vMainTemplate from "@/templates/main.vue";
import vAuthTemplate from "@/templates/auth.vue";

// pages
import ChatComponent from "@/components/vChatComponent.vue";
import AuthComponent from "@/components/vAuthForm.vue";
import NewsComponent from "@/components/vNews.vue";

const routes = [
    {
        path: "/",
        component: vMainTemplate,
        children: [
            {
                path: "chat",
                component: ChatComponent,
            },
            {
                path: "/news",
                component: NewsComponent,  
            },
        ],
        beforeEnter: (to, from, next) => {
            const $cookies = inject("$cookies");

            if (!$cookies.isKey("token")) {
                next("/auth/login");

                return;
            }

            next();
        },
    },
    {
        path: "/auth",
        component: vAuthTemplate,
        children: [
            {
                path: "login",
                component: AuthComponent,
            },
        ],
        beforeEnter: (to, from, next) => {
            const $cookies = inject("$cookies");

            if ($cookies.isKey("token")) {
                next("/chat");

                return;
            }

            next();
        },
    },
];

export default createRouter(
    {
        history: createWebHistory(),
        routes: routes,
    }
);
